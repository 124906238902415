import { COUNTRY_CODES, COUNTRY_PHONE_LIST } from '~/constants';
import { EMAIL_REGEX } from '~/constants/regex';
import { formatDate } from './dateUtils';
import { getWeek } from 'date-fns';
import { AddressType } from '~/types-and-enums/generalTypes';

/**
 * Retrieves the title of the current document.
 *
 * @return {string} The title of the document, or an empty string if the document does not have a title.
 */
export const getDocumentTitle = () => {
  if (typeof window !== 'undefined') {
    return document.getElementsByTagName('title')?.[0]?.innerHTML ?? '';
  }
  return '';
};

export const mergeMetaTagContent = (pageName: string, detailName?: string) => {
  return detailName ? detailName + ' | ' + pageName : pageName;
};

export const groupByWithOrder = <
  TKey extends keyof any,
  TElement extends Record<TKey, any>
>(
  array: TElement[],
  key: TKey
) => {
  const grouped = array.reduce((result, item) => {
    const groupKey = item[key];
    const group = result[groupKey] || [];
    group.push(item);
    result[groupKey] = group;
    return result;
  }, {} as Record<string, any[]>);

  const uniqueOrderedKeys = Array.from(new Set(array.map((item) => item[key])));
  return uniqueOrderedKeys.map((key) => [key, grouped[key]]);
};

export const isNil = (value: unknown) => value == null;

export const findKeyByValue = (
  obj: { [key: string]: string },
  value: string
) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const formatDecimalString = (inputString: string, locale = 'en-EN') => {
  const decimalNumber = parseFloat(inputString);

  if (isNaN(decimalNumber)) {
    return '';
  }

  if (decimalNumber % 1 === 0) {
    return decimalNumber.toFixed(0);
  } else {
    return decimalNumber.toLocaleString(locale);
  }
};

/**
 * Checks if the given email is associated with an employee.
 *
 * @param {string} email - The email to check.
 * @return {boolean} Returns true if the email is associated with an employee, false otherwise.
 */
export const checkUserIsEmployee = (email: string) => {
  const whiteList = [
    'aptstack@gmail.com',
    'andercoreexternal@gmail.com',
    'ka.majchrowska@gmail.com',
    'hello@chrissytopal.com',
  ];

  return (
    email?.endsWith('@andercore.com') ||
    whiteList.some((wlEmail) => wlEmail === email)
  );
};

/**
 * Formats a number to a string with a fixed number of decimal places.
 * @param number
 * @returns
 */
export const formatNumber = (
  value: string | number,
  decimalSpaces: number = 2
): string => {
  if (typeof value === 'number') {
    return value % 1 === 0 ? value.toString() : value.toFixed(decimalSpaces);
  } else if (typeof value === 'string') {
    let parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return parsedValue % 1 === 0
        ? parsedValue.toString()
        : parsedValue.toFixed(decimalSpaces);
    }
  }
  return value; // Handles other data types
};

/**
 * Format an amount based on the specified currency and locale.
 * - localizeAmountWithCurrency
 * @param amount - The amount to be formatted.
 * @param currency - The currency code (e.g., 'EUR', 'USD').
 * @param locale - The locale code (e.g., 'de', 'en').
 * @returns The formatted currency string.
 */
export const formatCurrency = (
  amount: number = 0.0,
  currency: string = 'EUR',
  locale: string = 'de'
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return Math.round((bytes / Math.pow(k, i)) * 100) / 100 + ' ' + sizes[i];
};

export const countNewlines = (text: string) => {
  let count = 0;
  let index = text.indexOf('\n');
  while (index !== -1) {
    count++;
    index = text.indexOf('\n', index + 1);
  }
  return count;
};

export const cleanStyles = (messageBody: string): string => {
  let htmlString = messageBody;
  let tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  let elementsWithStyle = tempDiv.querySelectorAll('[style]');
  elementsWithStyle.forEach((element) => {
    // @ts-ignore
    element.style.cssText = '';
  });

  let plainHtml = tempDiv.innerHTML;

  return plainHtml;
};

export const createFileName = (
  entityType: string,
  selectedFormat: string
): string => {
  const dateStr = formatDate(new Date());
  return `Andercore_${entityType}_${dateStr}.${selectedFormat}`;
};

export const validateEmail = (email: string) => {
  return String(email).toLowerCase().match(EMAIL_REGEX);
};

// Function to deeply merge two objects
export const deepMerge = (target: any, source: any) => {
  for (const key in source) {
    if (source[key] instanceof Object) {
      target[key] = deepMerge(target[key] || {}, source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
};

export enum ColorModeEnum {
  Stroke = 'stroke',
  Fill = 'fill',
}

/**
 * Calculate the difference in milliseconds between a future date and the current date
 * @param {string | Date} futureDate - The future date and time
 * @returns {number} - The difference in milliseconds
 */
export function getTimeDifferenceInMilliseconds(
  futureDate?: string | Date
): number {
  if (!futureDate) return 0;

  const now: Date = new Date();
  const currentDate = now.getTime();
  const future = new Date(futureDate).getTime();

  return future > currentDate ? future - currentDate : 0;
}

/**
 * Converts milliseconds to days, hours, minutes and seconds
 * @param milliseconds
 * @returns
 */
export const getDaysHoureMinutesSeconds = (
  milliseconds: number,
  d: string,
  h: string,
  m: string,
  s: string
) => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const daysStr = `${days}${d} `;
  const hoursStr = `${hours}${h} `;
  const minutesStr = `${minutes}${m} `;
  const secondsStr = `${seconds}${s}`;

  if (days > 0) {
    return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`;
  } else if (hours > 0) {
    return `${hoursStr}${minutesStr}${secondsStr}`;
  } else if (minutes > 0) {
    return `${minutesStr}${secondsStr}`;
  } else {
    return `${secondsStr}`;
  }
};

/**
 * Get the country code based on the country name
 * @param countryName - The name of the country
 * @returns The country code (e.g., 'DE')
 */
export const getCountryCode = (countryName?: string) => {
  if (!countryName) return ''; // If no country name is provided
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  for (const code of COUNTRY_CODES) {
    const localizedCountryName = regionNames.of(code);
    if (
      localizedCountryName?.toLowerCase() === countryName?.toLowerCase() ||
      localizedCountryName?.includes(countryName)
    ) {
      return code.toUpperCase(); // Return in upper case, e.g., 'DE'
    }
  }

  return ''; // If no match is found
};

/**
 * Check if two objects are deeply equal
 * @param firstObject
 * @param secondObject
 * @returns
 */
export const isObjectsDeepEqual = (
  firstObject: Record<string, any>,
  secondObject: Record<string, any>
): boolean => {
  if (firstObject === secondObject) {
    return true;
  }

  if (
    typeof firstObject !== 'object' ||
    firstObject == null ||
    typeof secondObject !== 'object' ||
    secondObject == null
  ) {
    return false;
  }

  const keys1 = Object.keys(firstObject);
  const keys2 = Object.keys(secondObject);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) =>
    isObjectsDeepEqual(firstObject[key], secondObject[key])
  );
};

export const findCountryByPhone = (phoneNumber: string) => {
  const countryMap: { [key: string]: string } = {};

  // Populate the map with country codes, use the country code as the key
  COUNTRY_PHONE_LIST.forEach((country) => {
    countryMap[country.code] = country.flagUrl;
  });

  // Sort the country list by the length of the country code, longest first
  const sortedCountryList = COUNTRY_PHONE_LIST.toSorted(
    (a, b) => b.code.length - a.code.length
  );

  // Iterate through the sorted list to find the longest matching country code
  for (let country of sortedCountryList) {
    if (phoneNumber.startsWith(country.code)) {
      return country; // Return the entire country object
    }
  }
  // If no country is found, return germany as default
  return COUNTRY_PHONE_LIST[0];
};

/**
 * Check if a value is empty (null, undefined, empty array, or empty object)
 * @param value - {}, [], null, undefined
 * @returns true if the value is empty, false otherwise
 */
export const isEmpty = (value: unknown): boolean => {
  if (value == null || value === '') return true; // Check for null or undefined or empty string

  if (Array.isArray(value)) {
    return value.length === 0; // Check if array is empty
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0; // Check if object has no keys
  }

  return false;
};

export const isNotEmpty = (value: any): boolean => !isEmpty(value);

/**
 * Check if a value is a number
 * @param value - The value to check
 * @returns true if the value is a number, false otherwise
 */
export const isNumber = (value: string | number) => !isNaN(Number(value));

/**
 * Returns address details as string
 * - Combines street, postal_code city, and country
 * - if postal_code is not provided, it is omitted
 * - if city is not provided, it is omitted
 * - if country is not provided, it is omitted
 * @param street - Ex: 'Musterstraße 123'
 * @param postal_code - Ex: '12345'
 * @param city - Ex: 'Berlin'
 * @param country - Ex: 'Germany'
 * @returns - Ex: 'Musterstraße 123, 12345 Berlin, Germany'
 */
export const formatAddress = ({
  street,
  postal_code,
  city,
  country,
}: {
  street?: string;
  postal_code?: string;
  city?: string;
  country?: string;
}): string => {
  const addressParts = [
    street,
    postal_code ? `${postal_code}${city ? ' ' + city : ''}` : city,
    country,
  ];

  return addressParts.filter(Boolean).join(', ');
};

/**
 * Returns the first found non-100 value and its index from the array of percentages
 * @param numberArray - Ex: [100,100,33,0,0,0]
 * @returns - Ex: { currentStatusPercentage: 33, currentStatusIndex: 2 }
 */
export const getFirstNonHundredValueAndIndex = (numberArray: number[]) => {
  const arrayLength = numberArray.length;
  for (let i = 0; i < arrayLength; i++) {
    if (numberArray[i] !== 100) {
      return { currentStatusPercentage: numberArray[i], currentStatusIndex: i };
    }
  }
  return {
    currentStatusPercentage: numberArray[arrayLength - 1],
    currentStatusIndex: arrayLength - 1,
  };
};

/**
 * Calculate the percentage of the sum of the values in the array
 * @param values - Ex: [100,100,33,0,0,0]
 */
export const calculatePercentage = (values: number[]): number => {
  if (values.length === 0) return 0; // Handle empty array

  const maxTotal = values.length * 100; // Maximum possible total
  const actualTotal = values.reduce((sum, value) => sum + value, 0); // Sum of array values

  // Calculate percentage and round down to the nearest integer
  return Math.floor((actualTotal / maxTotal) * 100);
};

/**
 * Get the week number of the year
 * @param date
 * @returns Week number - Ex: 46
 */
export const getWeekNumber = (date: Date) => {
  return getWeek(date, {
    weekStartsOn: 1,
    firstWeekContainsDate: 4,
  });
};

/**
 * Returns numeric value from a string
 * @param stringNumber
 * @returns
 */
export const stringToNumber = (stringNumber: string) => {
  return parseFloat(stringNumber.replace(/\./g, '').replace(',', '.'));
};
export const addCommaToNumber = (number: number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

/**
 * Compare the earliest delivery date with today and return the appropriate date.
 * @param delivery_date - The date to compare against today.
 * @returns The earlier of today or earliestDeliveryDate.
 */
export const getEffectiveDeliveryDate = (
  delivery_date: string | Date
): string => {
  const today = new Date();
  const deliveryDate = new Date(delivery_date);

  // Compare the dates
  if (deliveryDate < today) {
    return today.toISOString().split('T')[0]; // Return today's date in YYYY-MM-DD format
  }

  return deliveryDate.toISOString().split('T')[0]; // Return the earliest delivery date
};

/**
 * Check if the address is valid
 * @param address - The address to validate
 * @returns true if the address is valid, false otherwise
 */
export const isAddressValid = (address?: AddressType) => {
  const { street, postal_code, city, country } = address || {};
  return Boolean(street && postal_code && city && country);
};

/**
 * Check if the address is invalid
 * @param address - The address to validate
 * - street, postal_code, city, and country are required
 * @returns true if the address is invalid, false otherwise
 */
export const isAddressInvalid = (address?: AddressType) => {
  return !isAddressValid(address);
};
